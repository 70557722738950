import moment from 'moment-timezone'
import { extend } from 'vee-validate'
import { required, email, max_value, required_if, min } from 'vee-validate/dist/rules'
import { formatModelValue, cleanIdrFormat } from '@/_helpers'

extend('date_after_or_equal', {
    computesRequired: true,
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ],
    validate: (value, {target}) => {
        if (value !== null && String(value).trim() !== '') {
            const now = moment(moment(value).format('YYYY-MM-DD 00:00:00'))
            const end = moment(moment(target).format('YYYY-MM-DD 00:00:00'))
            const duration = moment.duration(now.diff(end))
            return duration.asSeconds() >= 0
        }
        return true
    },
    message: (fieldName, placeholders) => {
        return 'Isi kolom ini tidak dapat kurang dari ' + placeholders.target.replace(/_/g, ' ');
    }
})
extend('required_with', {
    computesRequired: true,
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ],
    validate: (value, {target}) => {
        if (target !== null && String(target).trim() !== '') {
            if (value === null || String(value).trim() === '') {
                return false
            }
        }
        return true
    },
    message: (fieldName, placeholders) => {
        return 'Kolom ini tidak dapat kosong jika ' + placeholders.target.replace(/_/g, ' ') + ' diisi';
    }
})
extend('max_value', {
    ...max_value,
    validate: (value, {max}) => {
        return max >= cleanIdrFormat(value)
    },
    message: (fieldName, placeholders) => {
        return 'Nilai kolom ini tidak dapat melebihi ' + formatModelValue('price', placeholders.max)
    }
})
extend('required', {
    ...required,
    message: 'Kolom ini wajib diisi'
})
extend('email', {
    ...email,
    message: 'Format email salah'
})
extend('min', {
    ...min,
    message: (fieldName, placeholders) => {
        return 'Isi kolom ini minimal ' + placeholders.length + ' karakter'
    }
})
extend('required_if_next_step', {
    ...required_if,
    message: (fieldName, placeholders) => {
        return 'Kolom ini wajib diisi jika akan ' + placeholders.target.replace(/_/g, ' ')
    }
})