<template>
    <div id="back-button">
        <b-btn variant="link" class="back-btn" @click.prevent="doClick">
            <span class="btn-icon-span">
                <font-awesome-icon icon="chevron-left"/>
            </span>
            <span class="btn-text-span">
                Kembali
            </span>
        </b-btn>
    </div>
</template>

<script>
export default {
    name: 'back-button',
    props: {
      defaultTo: {
        type: String,
        required: true
      }
    },
    methods: {
        doClick () {
            if (window.history.length > 1) {
                this.$router.go(-1)
            } else {
                this.$router.push(this.defaultTo)
            }
        }
    }
}
</script>

<style>
 .back-btn {
    border-width: 0px !important;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    /* position: absolute; */
    margin-top: -1rem;
    /* top: 0px; */
    color: #748194 !important;
    display: flex;
    align-items: center;
 }

 @media (max-width: 990px) {
    .back-btn {
        margin-top: -0.5rem;
    }
 }
 .back-btn:hover {
    text-decoration: none;
 }
 .btn-text-span {
    display: inline-flex;
    padding-left: 5px;
 }
 .back-btn .btn-icon-span {
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    display: inline-flex;
    height: 30px;
    width: 30px;
    color: #fff;
    background-color: #748194;
    border-color: #748194;
 }
</style>